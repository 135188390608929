import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInputCustom";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
import SliderCard from "components/cards/ThreeColSlider.js";
import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import FooterCustom from "components/footers/MiniCenteredFooterCustom.js";
import TabCardGrid from "components/cards/TabCardGrid";
import TabCardGridCustom from "components/cards/TabCardGridCustom";
import Header from "../components/headers/light.js";
import SimpleContactUsCustom from "components/forms/SimpleContactUsCustom";
import FloatingWhatsApp from "../components/FloatingWhatsApp.tsx";


export default () => {

  const [activeCategory, setActiveCategory] = useState(null);
  const [contactUsFormInterest, SetContactUsFormInterest] = useState('')

  const activeCatogory = (newActiveCategory) => {
    setActiveCategory(newActiveCategory);
  }

  const { search } = useLocation();

  const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

  useEffect(() => {

    const searchParams = new URLSearchParams(search)
    console.log('search params - more-info: ', searchParams.get('more-info'))
    if (searchParams.get('more-info') != null || searchParams.get('more-info') != undefined) {

      SetContactUsFormInterest(`Interested in Category: ${searchParams.get('more-info')}`)

      var element = document.getElementById("contactus-form");
      // element.scrollIntoView({scrollIntoViewOptions:{behavior:'smooth', block:'center'}});
      element.scrollIntoView();
    }

  }, [search])

  return (

    <>
      <StyledDiv className="App">
        <Header activeCatogory={activeCatogory} />
        <Hero />
        <TabCardGridCustom changeActiveTab={activeCategory} />
        <SimpleContactUsCustom contactUsFormInterest={contactUsFormInterest} />
        <FooterCustom />
        <FloatingWhatsApp
          phoneNumber="+919319747778"
          accountName="Corporate Solutions"
          allowClickAway
          notification
          notificationDelay={30000}
          notificationSound
        />
      </StyledDiv>
    </>
  );

}
