import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg"
import emailjs from "@emailjs/browser";
import Spinner from "components/Spinner";
import "../../styles_spinner.css"

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`

const isFormEmpty = (formData) => {
  return Object?.values(formData)?.some(value => value === "")
}

export default (props) => {

  console.log('contact us form props: ', props.contactUsFormInterest)

  useEffect(() => {

    setClientInfo((prevState) => {
      return { ...prevState, client_interests: props.contactUsFormInterest }
    })

  }, [props.contactUsFormInterest])

  const [clientInfo, setClientInfo] = useState(
    {
      client_name: "",
      client_email: "",
      client_mobile_number: "",
      client_interests: ""
    })
  const [clientQuerySubmission, setClientQuerySubmission] = useState("not_submitted")

  function submitClientQuery() {
    setClientQuerySubmission('loading')
    emailjs.send(
      "service_nqpm4eq",
      "template_znbwrfm",
      clientInfo,
      "E5p9vfv_GokJEvqkK"
    )
      .then(
        function (response) {
          setClientQuerySubmission('successful')
          setTimeout(() => {
            setClientInfo({
              client_name: "",
              client_email: "",
              client_mobile_number: "",
              client_interests: ""

            })
            setClientQuerySubmission('not_submitted')
          }, 5000)
        },
        function (error) {
          setClientQuerySubmission('error')
        }
      );
  }

  return (
    <Container id="contactus-form">
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            {(clientQuerySubmission == 'not_submitted') && (
              <>
                <h2>Contact Us</h2>
                <form>
                  <TwoColumn>
                    <Column>
                      <InputContainer>
                        <Label htmlFor="name-input">Your Name</Label>
                        <Input id="name-input" type="text" name="name" placeholder="E.g. John Doe" value={clientInfo.client_name} onChange={(e) => {
                          setClientInfo((prevState) => {
                            return { ...prevState, client_name: e.target.value }
                          })
                        }} />
                      </InputContainer>
                      <InputContainer>
                        <Label htmlFor="email-input">Your Email Address</Label>
                        <Input id="email-input" type="email" name="email" placeholder="E.g. john@mail.com" value={clientInfo.client_email} onChange={(e) => {
                          setClientInfo((prevState) => {
                            return { ...prevState, client_email: e.target.value }
                          })
                        }} />
                      </InputContainer>
                    </Column>
                    <Column>
                      <InputContainer>
                        <Label htmlFor="email-input">Your Mobile Number</Label>
                        <Input id="phone-number-input" type="number" name="email" placeholder="E.g. +91 9999800350" value={clientInfo.client_mobile_number} onChange={(e) => {
                          setClientInfo((prevState) => {
                            return { ...prevState, client_mobile_number: e.target.value }
                          })
                        }} />
                      </InputContainer>
                      <InputContainer tw="flex-1">
                        <Label htmlFor="name-input">Your Interests</Label>
                        <TextArea id="message-input" name="message" placeholder="E.g. Best products of noise and vadham" value={clientInfo.client_interests} onChange={(e) => {
                          setClientInfo((prevState) => {
                            return { ...prevState, client_interests: e.target.value }
                          })
                        }} />
                      </InputContainer>
                    </Column>
                  </TwoColumn>
                  <SubmitButton type="submit" value="Submit" onClick={submitClientQuery} disabled={isFormEmpty(clientInfo)}>Submit</SubmitButton>
                </form>
              </>
            )}
            {(clientQuerySubmission == 'loading') && (
              <div>
                <Paragraph>Submitting your query <Spinner /></Paragraph>
              </div>
            )}
            {(clientQuerySubmission == 'successful') && (
              <div>
                <Paragraph>Thank you for your valuable time, we will reach out to you shortly.</Paragraph>
              </div>
            )}
            {(clientQuerySubmission == 'error') && (
              <div>
                <Paragraph>An unexpected error occured, please contact us at sales@corporatesolutions.co.in or refresh this page and try again.</Paragraph>
              </div>
            )}
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};
