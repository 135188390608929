import { useRef, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import emailjs from "@emailjs/browser";
import Spinner from "components/Spinner";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import "../../styles_spinner.css";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0 mt-10 hidden lg:block`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;
const Actions2 = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0 lg:hidden block mt-10`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

export default () => {

  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  const [clientEmail, setClientEmail] = useState('')
  const [clientEmailSubmission, setClientEmailSubmission] = useState("not_submitted")

  function submitClientEmail() {
    if (clientEmail?.length > 0) {
      setClientEmailSubmission('loading')
      emailjs.send(
        "service_nqpm4eq",
        "template_znbwrfm",
        {
          client_name: "",
          client_email: clientEmail,
          client_mobile_number: "",
          client_interests: ""
        },
        "E5p9vfv_GokJEvqkK"
      )
        .then(
          function (response) {
            setClientEmailSubmission('successful')
            setTimeout(() => {
              setClientEmail('')
              setClientEmailSubmission('not_submitted')
            }, 5000)
          },
          function (error) {
            setClientEmailSubmission('error')
          }
        );
    }
  }

  return (
    <>
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              One-Stop <span tw="text-primary-500">Gifting Solution</span>
            </Heading>
            {/* <Paragraph>
            Elevate Your Gifting Experience: Welcome to the One-Stop Destination for Premium Corporate Gifts. With Curated Perfection and Thoughtful Gestures, We Strengthen Bonds and Celebrate Success in Style.
            </Paragraph> */}
            {(clientEmailSubmission == 'not_submitted' || clientEmailSubmission === "loading") && (
              <Actions>
                <input type="email" placeholder="Your E-mail Address" value={clientEmail} onChange={(e) => {
                  setClientEmail(e.target.value)
                }} />
                <button onClick={submitClientEmail}>
                  {clientEmailSubmission === "loading" ? <Spinner />
                    : "Get in touch"}
                </button>
              </Actions>
            )}
            {(clientEmailSubmission == 'successful') && (
              <Actions>
                <div>
                  <Paragraph>Thank you for your valuable time, we will reach out to you shortly.</Paragraph>
                </div>
              </Actions>
            )}
            {(clientEmailSubmission == 'error') && (
              <Actions>
                <div>
                  <Paragraph>An unexpected error occured, please contact us at sales@corporatesolutions.co.in or refresh this page and try again.</Paragraph>
                </div>
              </Actions>
            )}
          </LeftColumn>
          <RightColumn>
            {/* <IllustrationContainer>
              <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={DesignIllustration} alt="Design Illustration" />
            </IllustrationContainer> */}
            <Swiper
              spaceBetween={0}
              slidesPerView={'auto'}
              // speed={500}
              // loop={true}
              // touchRatio={1.5}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: true,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              // onAutoplayTimeLeft={onAutoplayTimeLeft}
              tw="min-w-0 w-full max-w-lg xl:max-w-3xl"
            >
              <SwiperSlide tw="flex items-center justify-center">
                <img
                  src='/brands_collage_1.png'
                  alt="Brands Collage 1"
                  className="slide-image"
                />
              </SwiperSlide>
              <SwiperSlide tw="flex items-center justify-center">
                <img
                  src='/brands_collage_2.png'
                  alt="Brands Collage 2"
                  className="slide-image"
                />
              </SwiperSlide>
              <SwiperSlide tw="flex items-center justify-center">
                <img
                  src='/brands_collage_3.png'
                  alt="Brands Collage 3"
                  className="slide-image"
                />
              </SwiperSlide>
              {/* <div className="autoplay-progress" slot="container-end">
                <svg viewBox="0 0 48 48" ref={progressCircle}>
                  <circle cx="24" cy="24" r="20"></circle>
                </svg>
                <span ref={progressContent}></span>
              </div> */}
            </Swiper>

            {(clientEmailSubmission == 'not_submitted') && (
              <Actions2>
                <input type="email" placeholder="Your E-mail Address" value={clientEmail} onChange={(e) => {
                  setClientEmail(e.target.value)
                }} />
                <button onClick={submitClientEmail}>Get in touch</button>
              </Actions2>
            )}
            {(clientEmailSubmission == 'loading') && (
              <Actions2>
                <div>
                  <Paragraph>Submitting Email <Spinner /></Paragraph>
                </div>
              </Actions2>
            )}
            {(clientEmailSubmission == 'successful') && (
              <Actions2>
                <div>
                  <Paragraph>Thank you for your valuable time, we will reach out to you shortly.</Paragraph>
                </div>
              </Actions2>
            )}
            {(clientEmailSubmission == 'error') && (
              <Actions2>
                <div>
                  <Paragraph>An unexpected error occured, please contact us at sales@corporatesolutions.co.in or refresh this page and try again.</Paragraph>
                </div>
              </Actions2>
            )}
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
