import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";


const Container = tw(ContainerBase)`bg-primary-500 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const Address = tw.div`text-center mt-10 font-medium tracking-wide text-sm text-white`
const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
const ConsentPreference = tw.span`underline text-center mt-8 font-medium tracking-wide text-sm text-white flex items-center gap-3 flex-wrap justify-center`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            {/* <LogoImg src={logo} /> */}
            <LogoText>Corporate Solutions</LogoText>
          </LogoContainer>
          <Address>
            <div>Address:</div>
            National Institute of Jewellery, Iind floor, C 46, Flatted Factory Complex,
            Rani Jhansi Road, Jhandewalan Extension, New Delhi, Central Delhi,
            Delhi, 110055
          </Address>
          {/* <LinksContainer>
            <Link href="#">Home</Link>
            <Link href="#">About</Link>
            <Link href="#">Contact Us</Link>
            <Link href="#">Blog</Link>
            <Link href="#">Reviews</Link>
          </LinksContainer> */}
          {/* <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer> */}

          <ConsentPreference>
            <a href="/privacy-notice.html" role="button"> Privacy Notice </a>
            <a href="/cookie-policy.html" role="button"> Cookie Policy </a>
            <a href="/terms-and-conditions.html" role="button"> Terms and Conditions </a>
            <a className="termly-display-preferences underline" role="button"> Consent Preferences </a>
          </ConsentPreference>
          <CopyrightText>
            &copy; Copyright {new Date()?.getFullYear()}, Corporate Solutions. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
