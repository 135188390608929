import React, { useEffect, useRef, useState } from "react";
import {useNavigate} from 'react-router-dom';
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import "./TabCardGridCustom.css";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${tw`h-56 xl:h-64 relative rounded-t flex items-center justify-center`}
`;
const Image = styled.img`
  ${tw`max-w-full rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm w-full`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  heading = "Checkout the Menu",
  tabs = {
    Brands: [
      {
        imageSrc:
          "/Assets/Brands/noise.png",
        type: '1',
        download_bttn_name: 'Download Catalogue',
        file_name: 'noise.pdf'
      },
      {
        imageSrc:
          "/Assets/Brands/wacaco.png",
        type: '1',
        download_bttn_name: 'Download Catalogue',
        file_name: 'wacaco.pdf'
      },
      {
        imageSrc:
          "/Assets/Brands/loyka.png",
        type: '1',
        download_bttn_name: 'Download Catalogue',
        file_name: 'loyka.pdf'
      },
      {
        imageSrc:
          "/Assets/Brands/unirec.png",
        type: '1',
        download_bttn_name: 'Download Catalogue',
        file_name: 'unirec.pdf'
      },
      {
        imageSrc:
          "/Assets/Brands/blue_tokai.png",
        type: '1',
        download_bttn_name: 'Download Catalogue',
        file_name: 'blue_tokai.pdf'
      },
      {
        imageSrc:
          "/Assets/Brands/boat.png",
        type: '1',
        download_bttn_name: 'Download Catalogue',
        file_name: 'boat.pdf'
      },
      {
        imageSrc:
          "/Assets/Brands/ekam.png",
        type: '1',
        download_bttn_name: 'Download Catalogue',
        file_name: 'ekam.pdf'
      },
      {
        imageSrc:
          "/Assets/Brands/hafele.png",
        type: '1',
        download_bttn_name: 'Download Catalogue',
        file_name: 'hafele.pdf'
      },
      {
        imageSrc:
          "/Assets/Brands/nasher_miles.png",
        type: '1',
        download_bttn_name: 'Download Catalogue',
        file_name: 'nasher_miles.pdf'
      },
      {
        imageSrc:
          "/Assets/Brands/portronics.png",
        type: '1',
        download_bttn_name: 'Download Catalogue',
        file_name: 'portronics.pdf'
      },
      {
        imageSrc:
          "/Assets/Brands/ras.png",
        type: '1',
        download_bttn_name: 'Download Catalogue',
        file_name: 'ras.pdf'
      },
      {
        imageSrc:
          "/Assets/Brands/vadham.png",
        type: '1',
        download_bttn_name: 'Download Catalogue',
        file_name: 'vadham.pdf'
      },
    ],
    Categories: [
      {
        imageSrc: "/Assets/Categories/BEVERAGES.webp",
        type: '2',
        bttn_name: 'More Info',
        title: 'Beverages'
      },
      {
        imageSrc:
          "/Assets/Categories/CHOCOLATES.jpeg",
          type: '2',
          bttn_name: 'More Info',
        title: 'Chocolates'
      },
      {
        imageSrc:
          "/Assets/Categories/DRINKWARE.png",
          type: '2',
          bttn_name: 'More Info',
        title: 'Drinkware'
      },
      {
        imageSrc:
          "/Assets/Categories/DRY FRUITS.jpeg",
          type: '2',
          bttn_name: 'More Info',
        title: 'Dry Fruits'
      },
      {
        imageSrc:
          "/Assets/Categories/GADGETS.jpeg",
          type: '2',
          bttn_name: 'More Info',
        title: 'Gadgets'
      },
      {
        imageSrc:
          "/Assets/Categories/HOME APPLIANCES.webp",
          type: '2',
          bttn_name: 'More Info',
        title: 'Home Appliances'
      },
      {
        imageSrc:
          "/Assets/Categories/INCENSE.jpeg",
          type: '2',
          bttn_name: 'More Info',
        title: 'Incense'
      },
      {
        imageSrc:
          "/Assets/Categories/SMARTWATCHES.jpeg",
          type: '2',
          bttn_name: 'More Info',
        title: 'Smart Watches'
      },
    ],
    
    // Products: getRandomCards(),
    // "By Budget": getRandomCards()
  },
  changeActiveTab
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */

  const navigate = useNavigate();

  // console.log("tabs", tabs);
  const tabsKeys = Object.keys(tabs);
  const ref = useRef();
  // const temp = changeActiveTab !== null ? changeActiveTab : tabsKeys[0].toLowerCase().replace(" ", "_");
  const [activeTab, setActiveTab] = useState(tabsKeys[0].toLowerCase().replace(" ", "_"));

  useEffect(() => {
    console.log("changeActiveTab", changeActiveTab);
    if (changeActiveTab !== null) {
      setActiveTab(changeActiveTab);
      ref.current.scrollIntoView();
    }
  }, [changeActiveTab]);

  return (
    <Container ref={ref}>
      <ContentWithPaddingXl>
        <HeaderRow>
          {/* <Header>{heading}</Header> */}
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl key={index} active={activeTab === tabName.toLowerCase().replace(" ", "_")} onClick={() => setActiveTab(tabName.toLowerCase().replace(" ", "_"))}>
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>
        {/* <div ref={ref}> */}
          {tabsKeys.map((tabKey, index) => (
            <TabContent
              id={tabKey.toLowerCase().replace(" ", "_")}
              key={index}
              variants={{
                current: {
                  opacity: 1,
                  scale: 1,
                  display: "flex",
                },
                hidden: {
                  opacity: 0,
                  scale: 0.8,
                  display: "none",
                }
              }}
              transition={{ duration: 0.4 }}
              initial={activeTab === tabKey.toLowerCase().replace(" ", "_") ? "current" : "hidden"}
              animate={activeTab === tabKey.toLowerCase().replace(" ", "_") ? "current" : "hidden"}
            >
              {tabs[tabKey].map((card, index) => (
                <CardContainer key={index}>
                  <Card className="group" href={card.url} initial="rest" whileHover="hover" animate="rest">
                    <CardImageContainer>
                      <Image src={card.imageSrc} className="image-in-container" />
                      {/* <CardRatingContainer>
                      <CardRating>
                        <StarIcon />
                        {card.rating}
                      </CardRating>
                      <CardReview>({card.reviews})</CardReview>
                    </CardRatingContainer> */}
                      {/* <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton>Buy Now</CardButton>
                    </CardHoverOverlay> */}
                    </CardImageContainer>
                  {(card.type == '1') && (
                    <a download href={`${process.env.PUBLIC_URL}/Assets/Catalogs/${card.file_name}`}><CardButton>{card.download_bttn_name}</CardButton></a>
                  )}
                  {(card.type == '2') && (
                    <>
                    <CardText>
                      <CardTitle>{card.title}</CardTitle>
                    </CardText>
                    <CardButton onClick={() => {
                      navigate(`/?more-info=${card.title}`);
                    }}>{card.bttn_name}</CardButton>
                    </>
                  )}
                  </Card>
                </CardContainer>
              ))}
            </TabContent>
          ))}
        {/* </div> */}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

/* This function is only there for demo purposes. It populates placeholder cards */
const getRandomCards = () => {
  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Chicken Chilled",
      content: "Chicken Main Course",
      price: "$5.99",
      rating: "5.0",
      reviews: "87",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1582254465498-6bc70419b607?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Samsa Beef",
      content: "Fried Mexican Beef",
      price: "$3.99",
      rating: "4.5",
      reviews: "34",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1565310022184-f23a884f29da?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Carnet Nachos",
      content: "Chilli Crispy Nachos",
      price: "$3.99",
      rating: "3.9",
      reviews: "26",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1534422298391-e4f8c172dddb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Guacamole Mex",
      content: "Mexican Chilli",
      price: "$3.99",
      rating: "4.2",
      reviews: "95",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1550461716-dbf266b2a8a7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Chillie Cake",
      content: "Deepfried Chicken",
      price: "$2.99",
      rating: "5.0",
      reviews: "61",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1476224203421-9ac39bcb3327??ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Nelli",
      content: "Hamburger & Fries",
      price: "$7.99",
      rating: "4.9",
      reviews: "89",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1455619452474-d2be8b1e70cd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Jalapeno Poppers",
      content: "Crispy Soyabeans",
      price: "$8.99",
      rating: "4.6",
      reviews: "12",
      url: "#"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1473093226795-af9932fe5856?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Cajun Chicken",
      content: "Roasted Chicken & Egg",
      price: "$7.99",
      rating: "4.2",
      reviews: "19",
      url: "#"
    }
  ];

  // Shuffle array
  return cards.sort(() => Math.random() - 0.5);
};
